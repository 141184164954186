import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../components/layout'
import Bio from "../components/bio"
import Subscribe from '../components/subscribeHubspot'
import ResumeSideBar from '../components/resumeSidebar'

const useStyles = makeStyles((theme) => ({
    Header: {
      [theme.breakpoints.up('xs')]: {
        textAlign: 'center',
      },
      [theme.breakpoints.up('sm')]: {
  
      },
      [theme.breakpoints.up('md')]: {
        textAlign: 'center',
      },
      [theme.breakpoints.up('lg')]: {
        textAlign: 'center',
      },
    },
    Body: {
      fontSize:'16px',
        [theme.breakpoints.up('xs')]: {
          marginLeft: '7.5%',
          marginRight: '7.5%'
        },
        [theme.breakpoints.up('sm')]: {
          marginLeft: '8%',
          marginRight: '8%'
        },
        [theme.breakpoints.up('md')]: {
          marginLeft: '10%',
          marginRight: '10%'
        },
        [theme.breakpoints.up('lg')]: {
          marginLeft: '15%',
          marginRight: '15%'
        },
    }
  })
  )
  
  const ApiTestingChecklist = () => {
    const classes = useStyles();
  
    return (
      
        <div className={classes.Body}>
          <ResumeSideBar />
        <div className={classes.Header}>
          <h1
          style={{
            marginTop:'8%',
            marginBottom: 0,
          }}
          >API Testing Checklist</h1>
          </div>
          <br/>
          <ol>
            <li>Make a list of the different APIs that exist in the organization, speak to stakeholders, and prioritize them</li>
            <li>Check that the APIs that exist are properly documented, written in a way that is easy to understand, and contain information such as:
              <ul className='sublist'
              style={
                {
                    listStyleType: 'circle',
                    paddingLeft: '1em'
                }
              }>
                <li>overview and purpose</li>
                <li>quick start guide and tutorials</li>
                <li>an example/sample of every call, every parameter, and responses for each call(errors included)</li>
                <li>user journey</li>
                <li>authentication and authorization</li>
                <li>rate limits</li>
                <li>code samples for commonly used languages</li>
                <li>schemas</li>
              </ul>
              </li>
            <li>Define the types of tests that you want to run:
              <ul className='sublist'
              style={
                {
                    listStyleType: 'circle',
                    paddingLeft: '1em'
                }
              }>
                <li>Positive scenarios return a valid response</li>
                <li>Invalid requests return the correct error message</li>
                <ul
                  style={
                    {
                        listStyleType: 'square',
                        paddingLeft: '3em'
                    }
                  }>
                  <li>Missing or invalid authorization token</li>
                  <li>Missing required parameters</li>
                  <li>Unsupported methods for endpoints</li>
                  <li>Invalid path/url</li>
                  <li>Invalid, incomplete or missing request body</li>
                  <li>Incorrect field names in request body</li>
                </ul>
                <li>Error Handling</li>
                <li>Schema match</li>
                <li>Workflow and data persistence</li>
                <li>Response time</li>
                <li>Specific standards and regulations that it should meet</li>
                <li>Response Payload - valid JSON body, correct field names, types, and values</li>
                <li>Response Headers</li>
                <li>Application state before and after API call</li>
                <li>Security and authorization
                  <ul
                  style={
                    {
                        listStyleType: 'square',
                        paddingLeft: '1em'
                    }
                  }>
                    <li>Invalid inputs</li>
                    <li>Injection attacks</li>
                    <li>Parameter tampering</li>
                    <li>Unhandled HTTP methods</li>
                    <li>Business logic vulnerabilities.</li>
                    <li>Authentication Expiry</li>
                    <li>Rate Limits</li>
                    <li>Content-Type Validation</li>
                    <li>Validate user inputs</li>
                    <li><a href='https://github.com/shieldfy/API-Security-Checklist#api-security-checklist' target="_blank" rel="noopener noreferrer">Take a look at this detailed API security checklist</a></li>
                    <li><a href='https://apimike.com/api-penetration-testing-checklist' target="_blank" rel="noopener noreferrer">This Penetration checklist covers some great tips</a></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>Evaluate and select an API testing tool.</li>
            <ul
              style={
                {
                    listStyleType: 'circle',
                    paddingLeft: '1em'
                }
              }>
                <li>Think about the types of tests that you will want to perform and if there are any specific needs that the tool will need to meet. </li>
                <li>Tools will also vary based on if you want to only execute them manually vs using an automated script.</li>
                <li>With API test automation you will also have to decide on a programming language and select tools within that language.</li>
              </ul>
          </ol>
        <hr
          style={{
            marginBottom: 25,
          }}
        />
        <footer
        style={
                {
                    fontSize:'14px',
                }
              }>
          <Bio />
        </footer>
        <div
          style={
            {
              textAlign: 'center',
              justifyContent: 'center',
              marginBottom: 50
            }
          }
        >
          <Subscribe />
        </div>
        </div>
    )
  }
  
  export default ApiTestingChecklist

